import React from 'react'

export default function NotFound() {
	return (
		<div>
			<h1>Page Not Found</h1>
			<p>Oops, we couldn't find this page.</p>
		</div>
	)
}
